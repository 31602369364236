<template>
	<metainfo></metainfo>

	<router-view />
</template>

<script>
export default {
	name: 'App'
};
</script>
